import React, { useContext } from 'react';
import { Locale, Window } from '../App';
import WeightLoss from '../assets/weightloss-min.png';
import MassGain from '../assets/prise_de_masse-min.png';
import Endurance from '../assets/endurance-min.png';
import Fitness from '../assets/remise_en_forme-min.png';
import Programming from '../assets/programmation-min.png';

export default function Services() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const services = [
        {
            label: translations[locale].perte_de_poids,
            image: WeightLoss,
            alt: "Deux pieds se trouvent sur une balance.",
        },
        {
            label: translations[locale].prise_de_masse,
            image: MassGain,
            alt: "Une femme est en train de manger des frites.",
        },
        {
            label: translations[locale].endurance,
            image: Endurance,
            alt: "Un homme en tenue de sport lasse ses chaussures.",
        },
        {
            label: translations[locale].remise_en_forme,
            image: Fitness,
            alt: "Une femme avec un casque audio s'étire sur un tapis de fitness.",
        },
        {
            label: translations[locale].programmation,
            image: Programming,
            alt: "Un homme en forme soulève une barre d'haltère.",
        },
    ];

    return (
        <div className={`services anchor_target ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
            <div id="Services" className="anchor"></div>
            <h2 className={`title ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                {translations[locale].services}
            </h2>
            <p className={`${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}
                dangerouslySetInnerHTML={{ __html: translations[locale].services_text }}>
            </p>
            <div className={`services__container ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                <div className="services__items">
                    {services.map((service, index) => (
                        <div key={index}
                            className={`services__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                            <div className={`services__items__item__image ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                <img className="services__items__item__image__img"
                                    src={service.image}
                                    alt={service.alt} />
                            </div>
                            {service.label}
                        </div>
                    ))}
                    {services.map((service, index) => (
                        <div key={index}
                            className={`services__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                            <div className={`services__items__item__image ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                <img className="services__items__item__image__img"
                                    src={service.image}
                                    alt={service.alt} />
                            </div>
                            {service.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}