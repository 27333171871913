import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import './styles/styles.scss';
import Home from './components/Home';

export const Locale = React.createContext(null);
export const Prices = React.createContext(null);
export const Window = React.createContext(null);
export const Sections = React.createContext(null);

function App() {
  const [locale, setLocale] = useState("fr");
  const [prices, setPrices] = useState([]);
  const [windowSizes, setWindowSizes] = useState({ height: window.innerHeight, width: window.innerWidth });
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [breakpoints, setBreakpoints] = useState({ mobile: 0, tablet: 700, desktop: 1300 });

  const translations = {
    "fr": {
      depasse_tes_limites: "Dépasse tes limites.",
      atteins_tes_objectifs: "Atteins tes objectifs.",
      a_domicile: "À domicile",
      a_distance: "À distance",
      en_exterieur: "En extérieur",
      bouton_premier_bilan: "Demander un premier bilan gratuit",
      bouton_en_savoir: "En savoir plus sur moi",
      sections: ["Présentation", "Services", "Tarifs", "Contact"],
      presentation: "Presentation",
      presentation_text: `Salut, moi c'est <span>Nicolas</span>&nbsp;!<br /><br />
        Je suis un grand <span>passionné de sport</span> et j'adore partager mes connaissances dans le milieu.<br /><br />
        Je suis <span>coach diplômé</span> et je suis là pour te conseiller, t'aider et te montrer le chemin jusqu'à la <span>silhouette</span> de tes plus grands <span>rêves</span> (et surtout pour améliorer <span>ta santé</span>&nbsp;!).<br /><br />
        Que tu sois à la recherche d'une <span>meilleure version de toi-même</span>, que tu aies des <span>objectifs bien précis</span> ou bien que tu cherches simplement à te <span>remettre en forme</span>, je serai à ton entière disposition - en présentiel ou en distanciel - afin de te guider dans ta quête jusqu'au <span>Graal</span>&nbsp;!`,
      services: "Services",
      services_text: `Tous les services que je te propose sont disponibles <span>à ton domicile</span>, <span>en exterieur</span> (dans un parc, par exemple) ou bien <span>à distance</span> (en visioconférence, ou par appel vidéo).<br /><br />
        Cela permet une <span>flexibilité maximale</span> pour toi, afin que tu ne te préoccupes de rien sauf <span>l'effort</span> pendant tes <span>séances</span>&nbsp;!`,
      perte_de_poids: "Perte de poids",
      prise_de_masse: "Prise de masse",
      endurance: "Endurance",
      remise_en_forme: "Remise en forme",
      programmation: "Programmation",
      tarifs: "Tarifs",
      tarifs_text: `Les <span>prestations</span> incluent toutes un <span>bilan complet</span> avant le démarrage de ton programme.<br /><br />
        Ce <span>bilan</span> aborde plusieurs points&nbsp;:`,
      passe_sportif: "Passé sportif",
      antecedents: "Antécédents médicaux",
      composition: "Composition corporelle",
      habitudes: "Habitudes alimentaires",
      rythme: "Rythme de vie",
      articulations: "Articulations et mobilité",
      force_physique: "Force physique",
      technique: "Technique d'éxecution",
      en_presentiel: "En présentiel",
      etudiant_ou_couple: `En tant qu'étudiant ou pour une demande de prestation en duo, tu as le droit à <span>-10%</span> sur les prix affichés.`,
      afficher_les_prix: "Afficher les tarifs étudiant/duo",
      bilan_inclus: "Bilan inclus",
      suivi_inclus: "Suivi inclus",
      suivi_selon: "Suivi selon durée",
      seance_simple: "Séance simple 1H",
      carnet_10: "Carnet de 10 séances",
      carnet_20: "Carnet de 20 séances",
      abonnement_1: "Abonnement 1 mois",
      abonnement_3: "Abonnement 3 mois",
      abonnement_6: "Abonnement 6 mois",
      abonnement_12: "Abonnement 12 mois",
      programme_personnalise: "Programme personnalisé",
      tu_souhaites_un_programme: "Tu souhaites un programme spécifique par rapport à tes besoins et tes moyens ? N'hésite pas à me contacter ci-dessous afin que l'on trouve ensemble le meilleur chemin vers tes objectifs.",
      sur_devis: "Sur devis",
      revient_a: "Revient à",
      la_seance: "la séance",
      par_mois: "par mois",
      contact: "Contact",
      suis_moi: "Suis-moi sur mes réseaux sociaux",
      contacte_moi_directement: "Contacte-moi directement",
      par_telephone: "Téléphone",
      par_mail: "Mail",
      ou_contacte_moi_par_message: "Ou laisse-moi tes coordonnées pour que je puisse te recontacter moi-même",
      comment_tu_tappelles: "Comment t'appelles-tu ?",
      renseigne_au_moins: "Renseigne au moins un de ces deux moyens de contact afin que je puisse te répondre. Tu peux aussi renseigner les deux.",
      mail: "Ton adresse e-mail",
      telephone: "Ton numéro de téléphone",
      adresse_postale: "Ton adresse postale",
      prestations_susceptibles: "Sélectionne les prestations susceptibles de t'intéresser ou liées à ta situation. Libre à toi de ne rien cocher.",
      seance_simple_alt: "Séance simple",
      carnet_de_seances: "Carnet de séances",
      abonnement_court: "Abonnement court",
      abonnement_long: "Abonnement long",
      decris_ta_situation: "Enfin, décris-moi tes situations personnelle et sportive actuelles ou bien renseigne tes demandes complémentaires ici.",
      ecris_ici: "Commence à écrire ici...",
      erreur_formulaire: "Veuillez remplir tous les champs du formulaire.",
      formulaire_valide: "Ta demande m'a bien été parvenue. Je te répondrai sous les plus brefs délais. Merci pour ta confiance, et à bientôt !",
      formulaire_invalide: "Oups, une erreur est survenue lors de l'envoi du mail. S'il te plait, réessaie plus tard ou contacte-moi directement via mon téléphone ou mon adresse e-mail.",
      soumettre_ma_demande: "Confirmer l'envoi",
      plan_du_site: "Plan du site",
      tous_droits_reserves: "Tous droits réservés",
      developpe_par: "Développé par",
    },
    "en": {
      depasse_tes_limites: "Exceed your limits.",
      atteins_tes_objectifs: "Achieve your goals.",
      a_domicile: "Home",
      a_distance: "Remotely",
      en_exterieur: "Outdoors",
      bouton_premier_bilan: "Ask for a free initial checkup",
      bouton_en_savoir: "Learn more about me",
      sections: ["About me", "Services", "Prices", "Contact"],
      presentation: "About me",
      presentation_text: `Hi, I’m <span>Nicolas</span>!<br /><br />
        I am a <span>big sports fan</span> and I love sharing my knowledge in the industry.<br /><br />
        I am a <span>qualified coach</span> and I am here to advise you, help you and show you the way to the <span>figure</span> of your <span>biggest dreams</span> (and above all to improve <span>your health</span>!).<br /><br />
        Whether you are looking for a <span>better version of yourself</span>, whether you have <span>very specific goals</span> or whether you are simply looking to <span>get back in shape</span>, I will be at your complete disposal - in person or remotely - to help you. guide you in your quest to the <span>Grail</span>!`,
      services: "Services",
      services_text: `All the services that I offer you are available <span>at your home</span>, <span>outdoors</span> (in a park, for example) or <span> remotely</span> (by videoconference, or by video call).<br /><br />
        This allows <span>maximum flexibility</span> for you, so that you don't have to worry about anything except <span>effort</span> during your <span>sessions</span>!`,
      perte_de_poids: "Weight loss",
      prise_de_masse: "Mass gain",
      endurance: "Endurance",
      remise_en_forme: "Fitness",
      programmation: "Programming",
      tarifs: "Prices",
      tarifs_text: `The <span>services</span> all include a <span>complete assessment</span> before starting your program.<br /><br />
        This <span>report</span> addresses several points:`,
      passe_sportif: "Sporting past",
      antecedents: "Medical background",
      composition: "Body composition",
      habitudes: "Eating habits",
      rythme: "Lifestyle",
      articulations: "Joints and mobility",
      force_physique: "Physical force",
      technique: "Execution technique",
      en_presentiel: "In person",
      etudiant_ou_couple: `As a student or for a duo session, you can benefit from <span>-10%</span> on the prices displayed.`,
      afficher_les_prix: "Show student/duo prices",
      bilan_inclus: "Initial checkup included",
      suivi_inclus: "Regular checkup included",
      suivi_selon: "Regular checkup depending on duration",
      seance_simple: "Simple session 1H",
      carnet_10: "10 sessions pack",
      carnet_20: "20 sessions pack",
      abonnement_1: "1 month subscription",
      abonnement_3: "3 months subscription",
      abonnement_6: "6 months subscription",
      abonnement_12: "12 months subscription",
      programme_personnalise: "Personalized program",
      tu_souhaites_un_programme: "Do you want a specific program based on your needs and means? Do not hesitate to contact me below so that we can find the best path to your goals together.",
      sur_devis: "On quotation",
      revient_a: "Comes to",
      la_seance: "per session",
      par_mois: "per month",
      contact: "Contact",
      suis_moi: "Follow me on my social networks",
      contacte_moi_directement: "Contact me directly",
      par_telephone: "Phone",
      par_mail: "Email",
      ou_contacte_moi_par_message: "Or leave me your contact details so I can contact you myself",
      comment_tu_tappelles: "What's your name?",
      renseigne_au_moins: "Provide at least one of these two contact methods so that I can respond to you. You can also enter both!",
      mail: "Your mail address",
      telephone: "Your phone number",
      adresse_postale: "Your postal address",
      prestations_susceptibles: "Select the services that may interest you or relate to your situation. You are free not to check anything.",
      seance_simple_alt: "Simple session",
      carnet_de_seances: "Sessions pack",
      abonnement_court: "Short subscription",
      abonnement_long: "Long subscription",
      decris_ta_situation: "Finally, describe your current personal and sporting situations to me or provide your additional requests here.",
      ecris_ici: "Start writing here...",
      erreur_formulaire: "Please complete all fields of the form.",
      formulaire_valide: "Your request has been received by me. I will respond to you as soon as possible. Thank you for your trust, and see you soon!",
      formulaire_invalide: "Oops, an error occurred while sending the email. Please try again later or contact me directly by my phone or email address.",
      soumettre_ma_demande: "Confirm the sending",
      plan_du_site: "Site map",
      tous_droits_reserves: "All rights reserved",
      developpe_par: "Webdesigned by",
    },
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSizes({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  });

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const debounce = (fn, ms) => {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms)
    };
  }

  return (
    <React.Fragment>
      <Router>
        <Locale.Provider value={{ locale: locale, setLocale: setLocale, translations: translations }}>
          <Window.Provider value={{ windowSizes: windowSizes, setWindowSizes: setWindowSizes, scrollPosition: scrollPosition, setScrollPosition: setScrollPosition, breakpoints: breakpoints, setBreakpoints: setBreakpoints }}>
            <Prices.Provider value={{ prices: prices, setPrices: setPrices }}>
              <Routes>
                <Route exact path="/" element={<Home />} />
              </Routes>
            </Prices.Provider>
          </Window.Provider>
        </Locale.Provider>
      </Router>
    </React.Fragment>
  );
}

export default App;
library.add(fab, fas, far);
