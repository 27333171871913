import React, { useContext, useEffect } from 'react';
import { Window } from '../App';
import Header from './Header';
import Hero from './Hero';
import AboutMe from './AboutMe';
import GoUpArrow from './GoUpArrow';
import Services from './Services';
import Prices from './Prices';
import Contact from './Contact';
import Footer from './Footer';

export default function Home() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <React.Fragment>
            <GoUpArrow />
            <Header />
            <Hero />
            <div className={`main ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                <AboutMe />
                <Services />
                <Prices />
                <Contact />
            </div>
            <Footer />
        </React.Fragment>
    )
}