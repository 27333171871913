import React, { useState, useContext } from 'react';
import { Window, Locale } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import frFlag from '../assets/france.jpeg';
import ukFlag from '../assets/uk.png';
import logo from '../assets/N&F - 192.png';

export default function Header() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, setLocale, translations } = useContext(Locale);

    const [opened, setOpened] = useState(false);
    const [localesMenu, setLocalesMenu] = useState(false);

    const getBackgroundOpacity = () => {
        if (scrollPosition < 30) {
            return 0;
        } else if (scrollPosition >= 30) {
            return 50;
        } else {
            return scrollPosition;
        }
    }

    const getBoxShadowOpacity = () => {
        if (scrollPosition > 30) {
            return 100;
        } else {
            return 0;
        }
    }

    return (
        <div className="header_container"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.' + getBackgroundOpacity() + ')', boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.' + getBoxShadowOpacity() + ')', backdropFilter: !opened && getBackgroundOpacity() == 50 ? 'blur(10px)' : '' }}>
            <div className={`header ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")} ${scrollPosition < 30 ? "top" : ""}`}>
                <img className={`header__logo ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")} ${scrollPosition >= 30 ? "normal" : ""}`}
                    src={logo}
                    alt="Nico&Fit Logo" />
                <nav className={`header__menu ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    {windowSizes.width < breakpoints.tablet ?
                        <React.Fragment>
                            <button className="header__menu__button"
                                onClick={() => {
                                    setOpened(opened ? false : true);
                                }}>
                                <FontAwesomeIcon icon="fa-solid fa-bars" className={`${opened ? "invisible" : "visible"}`} />
                                <FontAwesomeIcon icon="fa-solid fa-times" className={`${opened ? "visible" : "invisible"}`} />
                            </button>
                        </React.Fragment>
                        : <React.Fragment>
                            {translations[locale].sections.map((section, index) => (
                                <a key={index}
                                    href={`#${section === "Présentation" ? "Presentation" : section}`}
                                    className="header__menu__section">
                                    {section}
                                </a>
                            ))}
                            <div className="header__menu__section__language__container">
                                <button className="header__menu__section__language header__menu__section__language--desktop"
                                    onClick={() => setLocale("fr")}>
                                    <div className={`header__menu__section__language__flag ${locale === "fr" ? "active" : "disabled"}`}>
                                        <img className="header__menu__section__language__flag__img"
                                            src={frFlag}
                                            alt="Le drapeau de la France." />
                                    </div>
                                </button>
                                <button className="header__menu__section__language header__menu__section__language--desktop"
                                    onClick={() => setLocale("en")}>
                                    <div className={`header__menu__section__language__flag ${locale === "en" ? "active" : "disabled"}`}>
                                        <img className="header__menu__section__language__flag__img"
                                            src={ukFlag}
                                            alt="The United Kingdom flag." />
                                    </div>
                                </button>
                            </div>
                        </React.Fragment>
                    }
                </nav>
            </div>
            {windowSizes.width < breakpoints.tablet &&
                <nav className={`header__menu__mobile ${opened ? "visible" : "invisible"}`}>
                    {translations[locale].sections.map((section, index) => (
                        <a key={index}
                            href={`#${section === "Présentation" ? "Presentation" : section}`}
                            className="header__menu__mobile__section"
                            onClick={() => setOpened(false)}>
                            {section}
                        </a>
                    ))}
                    <div className="header__menu__section__languages">
                        <button className={`header__menu__section__language ${locale === "fr" ? "active" : "disabled"}`}
                            onClick={() => setLocale("fr")}>
                            <div className="header__menu__section__language__flag">
                                <img className="header__menu__section__language__flag__img"
                                    src={frFlag}
                                    alt="Le drapeau de la France." />
                            </div>
                        </button>
                        <button className={`header__menu__section__language ${locale === "en" ? "active" : "disabled"}`}
                            onClick={() => setLocale("en")}>
                            <div className="header__menu__section__language__flag">
                                <img className="header__menu__section__language__flag__img"
                                    src={ukFlag}
                                    alt="The United Kingdom flag." />
                            </div>
                        </button>
                    </div>
                </nav>
            }
        </div>
    )
}