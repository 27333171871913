import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Window } from "../App";
import { useContext } from "react";

export default function GoUpArrow() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);

    const getBackgroundOpacity = () => {
        if (scrollPosition < 1) {
            return 0;
        } else if (scrollPosition >= 1) {
            return 1;
        } else {
            return scrollPosition;
        }
    }

    const goUp = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <button className={`arrow ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}
            onClick={() => goUp()}
            style={{ filter: 'opacity(' + getBackgroundOpacity() + ')' }}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-up" />
        </button>
    )
}