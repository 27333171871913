import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Locale, Window } from '../App';

export default function Prices() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const [isPresentiel, setIsPresentiel] = useState(true);
    const [isReduced, setIsReduced] = useState(false);

    const prices = [
        {
            label: translations[locale].seance_simple,
            price: 45,
            seances: 1,
            suivi: false,
        },
        {
            label: translations[locale].carnet_10,
            price: 400,
            seances: 10,
            suivi: true,
        },
        {
            label: translations[locale].carnet_20,
            price: 700,
            seances: 20,
            suivi: true,
        },
    ];

    const pricesDistanciel = [
        {
            label: translations[locale].abonnement_1,
            price: 90,
            months: 1,
            suivi: false,
        },
        {
            label: translations[locale].abonnement_3,
            price: 250,
            months: 3,
            suivi: false,
        },
        {
            label: translations[locale].abonnement_6,
            price: 450,
            months: 6,
            suivi: true,
        },
        {
            label: translations[locale].abonnement_12,
            price: 850,
            months: 12,
            suivi: true,
        },
    ];

    const bilan = [
        {
            icon: "fa-solid fa-medal",
            label: translations[locale].passe_sportif,
        },
        {
            icon: "fa-solid fa-suitcase-medical",
            label: translations[locale].antecedents,
        },
        {
            icon: "fa-solid fa-child-reaching",
            label: translations[locale].composition,
        },
        {
            icon: "fa-solid fa-utensils",
            label: translations[locale].habitudes,
        },
        {
            icon: "fa-solid fa-heart-pulse",
            label: translations[locale].rythme,
        },
        {
            icon: "fa-solid fa-bone",
            label: translations[locale].articulations,
        },
        {
            icon: "fa-solid fa-hand-fist",
            label: translations[locale].force_physique,
        },
        {
            icon: "fa-solid fa-person-running",
            label: translations[locale].technique,
        },
    ];

    return (
        <div className={`prices anchor_target ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
            <div id={translations[locale].tarifs} className="anchor"></div>
            <h2 className="title">
                {translations[locale].tarifs}
            </h2>
            <p className="prices__text"
                dangerouslySetInnerHTML={{ __html: translations[locale].tarifs_text }}>
            </p>
            <div className={`prices__bilan ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                {bilan.map((item, index) => (
                    <div key={index}
                        className={`prices__bilan__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                        <FontAwesomeIcon icon={item.icon} />
                        {item.label}
                    </div>
                ))}
            </div>
            <div className="prices__separator"></div>
            {windowSizes.width < breakpoints.desktop ?
                <React.Fragment>
                    <p className="prices__disclaimer"
                        dangerouslySetInnerHTML={{ __html: translations[locale].etudiant_ou_couple }}>
                    </p>
                    <button className={`prices__filter__student ${isReduced ? "active" : "disabled"}`}
                        onClick={() => setIsReduced(isReduced ? false : true)}>
                        {translations[locale].afficher_les_prix}
                    </button>
                </React.Fragment>
                : <React.Fragment>
                    <div className="prices__disclaimer--desktop">
                        <p className="prices__disclaimer"
                            dangerouslySetInnerHTML={{ __html: translations[locale].etudiant_ou_couple }}>
                        </p>
                        <button className={`prices__filter__student ${isReduced ? "active" : "disabled"}`}
                            onClick={() => setIsReduced(isReduced ? false : true)}>
                            {translations[locale].afficher_les_prix}
                        </button>
                    </div>
                    <div className="prices__separator alt"></div>
                </React.Fragment>
            }
            {windowSizes.width < breakpoints.desktop ?
                <div className={`prices__filter ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    <div className="prices__filter__category">
                        <button className={`prices__filter__category__button ${isPresentiel ? "active" : "disabled"}`}
                            onClick={() => setIsPresentiel(true)}>
                            {translations[locale].en_presentiel}
                        </button>
                        <button className={`prices__filter__category__button ${isPresentiel ? "disabled" : "active"}`}
                            onClick={() => setIsPresentiel(false)}>
                            {translations[locale].a_distance}
                        </button>
                    </div>
                </div>
                : <div className="prices__titles">
                    <p className="prices__titles__title">
                        {translations[locale].en_presentiel}
                    </p>
                    <p className="prices__titles__title">
                        {translations[locale].a_distance}
                    </p>
                </div>
            }
            {windowSizes.width < breakpoints.desktop ?
                <div className={`prices__items ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    {isPresentiel ?
                        <React.Fragment>
                            {prices.map((price, index) => (
                                <div key={index}
                                    id={price.label}
                                    className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                    <label className="prices__items__item__label"
                                        htmlFor={price.label}>
                                        {price.label}
                                    </label>
                                    {isReduced ?
                                        <p className="prices__items__item__price">
                                            <span className="prices__items__item__price__old">{price.price}€</span>
                                            <span className="prices__items__item__price__new">{Math.floor(price.price * 0.9)}€</span>
                                        </p>
                                        : <p className="prices__items__item__price">
                                            {price.price}€
                                        </p>
                                    }
                                    {price.seances !== 1 &&
                                        <p className="prices__items__item__seance">
                                            {isReduced ? `${translations[locale].revient_a} ${Math.floor((price.price * 0.9) / price.seances)}€ ${translations[locale].la_seance}`
                                                : `${translations[locale].revient_a} ${Math.floor(price.price / price.seances)}€ ${translations[locale].la_seance}`
                                            }
                                        </p>
                                    }
                                    <div className="prices__items__item__bilansuivi">
                                        <p className="prices__items__item__bilansuivi__bilan">
                                            {translations[locale].bilan_inclus}
                                        </p>
                                        {price.suivi &&
                                            <p className="prices__items__item__bilansuivi__suivi">
                                                {translations[locale].suivi_inclus}
                                            </p>
                                        }
                                    </div>
                                    {isReduced &&
                                        <div className="prices__items__item__bandeau">
                                            - 10%
                                        </div>
                                    }
                                </div>
                            ))}
                        </React.Fragment>
                        : <React.Fragment>
                            {pricesDistanciel.map((price, index) => (
                                <div key={index}
                                    id={price.label}
                                    className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                    <label className="prices__items__item__label"
                                        htmlFor={price.label}>
                                        {price.label}
                                    </label>
                                    {isReduced ?
                                        <p className="prices__items__item__price">
                                            <span className="prices__items__item__price__old">{price.price}€</span>
                                            <span className="prices__items__item__price__new">{Math.floor(price.price * 0.9)}€</span>
                                        </p>
                                        : <p className="prices__items__item__price">
                                            {price.price}€
                                        </p>
                                    }
                                    {price.months !== 1 &&
                                        <p className="prices__items__item__seance">
                                            {isReduced ? `${translations[locale].revient_a} ${Math.floor((price.price * 0.9) / price.months)}€ ${translations[locale].par_mois}`
                                                : `${translations[locale].revient_a} ${Math.floor(price.price / price.months)}€ ${translations[locale].par_mois}`
                                            }
                                        </p>
                                    }
                                    <div className="prices__items__item__bilansuivi">
                                        <p className="prices__items__item__bilansuivi__bilan">
                                            {translations[locale].bilan_inclus}
                                        </p>
                                        {price.suivi &&
                                            <p className="prices__items__item__bilansuivi__suivi">
                                                {translations[locale].suivi_inclus}
                                            </p>
                                        }
                                    </div>
                                    {isReduced &&
                                        <div className="prices__items__item__bandeau">
                                            - 10%
                                        </div>
                                    }
                                </div>
                            ))}
                        </React.Fragment>
                    }
                    <div id="programme-personnalise"
                        className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                        <label className="prices__items__item__label"
                            htmlFor="programme-personnalise">
                            {translations[locale].programme_personnalise}
                        </label>
                        <p className="prices__items__item__price">
                            {translations[locale].sur_devis}
                        </p>
                        <p className="prices__items__item__seance">
                            {translations[locale].tu_souhaites_un_programme}
                        </p>
                        <div className="prices__items__item__bilansuivi">
                            <p className="prices__items__item__bilansuivi__bilan">
                                {translations[locale].bilan_inclus}
                            </p>
                            <p className="prices__items__item__bilansuivi__suivi">
                                {translations[locale].suivi_selon}
                            </p>
                        </div>
                    </div>
                </div>
                : <div className="prices__items prices__items--desktop">
                    <div className="prices__items__left">
                        {prices.map((price, index) => (
                            <div key={index}
                                id={price.label}
                                className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                <label className="prices__items__item__label"
                                    htmlFor={price.label}>
                                    {price.label}
                                </label>
                                {isReduced ?
                                    <p className="prices__items__item__price">
                                        <span className="prices__items__item__price__old">{price.price}€</span>
                                        <span className="prices__items__item__price__new">{Math.floor(price.price * 0.9)}€</span>
                                    </p>
                                    : <p className="prices__items__item__price">
                                        {price.price}€
                                    </p>
                                }
                                {price.seances !== 1 &&
                                    <p className="prices__items__item__seance">
                                        {isReduced ? `${translations[locale].revient_a} ${Math.floor((price.price * 0.9) / price.seances)}€ ${translations[locale].la_seance}`
                                            : `${translations[locale].revient_a} ${Math.floor(price.price / price.seances)}€ ${translations[locale].la_seance}`
                                        }
                                    </p>
                                }
                                <div className="prices__items__item__bilansuivi">
                                    <p className="prices__items__item__bilansuivi__bilan">
                                        {translations[locale].bilan_inclus}
                                    </p>
                                    {price.suivi &&
                                        <p className="prices__items__item__bilansuivi__suivi">
                                            {translations[locale].suivi_inclus}
                                        </p>
                                    }
                                </div>
                                {isReduced &&
                                    <div className="prices__items__item__bandeau">
                                        - 10%
                                    </div>
                                }
                            </div>
                        ))}
                        <div id="programme-personnalise-2"
                            className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                            <label className="prices__items__item__label"
                                htmlFor="programme-personnalise-2">
                                {translations[locale].programme_personnalise}
                            </label>
                            <p className="prices__items__item__price">
                                {translations[locale].sur_devis}
                            </p>
                            <p className="prices__items__item__seance">
                                {translations[locale].tu_souhaites_un_programme}
                            </p>
                            <div className="prices__items__item__bilansuivi">
                                <p className="prices__items__item__bilansuivi__bilan">
                                    {translations[locale].bilan_inclus}
                                </p>
                                <p className="prices__items__item__bilansuivi__suivi">
                                    {translations[locale].suivi_selon}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="prices__items__right">
                        {pricesDistanciel.map((price, index) => (
                            <div key={index}
                                id={price.label}
                                className={`prices__items__item ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                                <label className="prices__items__item__label"
                                    htmlFor={price.label}>
                                    {price.label}
                                </label>
                                {isReduced ?
                                    <p className="prices__items__item__price">
                                        <span className="prices__items__item__price__old">{price.price}€</span>
                                        <span className="prices__items__item__price__new">{Math.floor(price.price * 0.9)}€</span>
                                    </p>
                                    : <p className="prices__items__item__price">
                                        {price.price}€
                                    </p>
                                }
                                {price.months !== 1 &&
                                    <p className="prices__items__item__seance">
                                        {isReduced ? `${translations[locale].revient_a} ${Math.floor((price.price * 0.9) / price.months)}€ ${translations[locale].par_mois}`
                                            : `${translations[locale].revient_a} ${Math.floor(price.price / price.months)}€ ${translations[locale].par_mois}`
                                        }
                                    </p>
                                }
                                <div className="prices__items__item__bilansuivi">
                                    <p className="prices__items__item__bilansuivi__bilan">
                                        {translations[locale].bilan_inclus}
                                    </p>
                                    {price.suivi &&
                                        <p className="prices__items__item__bilansuivi__suivi">
                                            {translations[locale].suivi_inclus}
                                        </p>
                                    }
                                </div>
                                {isReduced &&
                                    <div className="prices__items__item__bandeau">
                                        - 10%
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}