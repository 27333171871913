import { useContext } from 'react';
import { Locale, Window } from '../App';
import image from '../assets/presentation.jpeg';

export default function AboutMe() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    return (
        <div className={`presentation anchor_target ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
            <div id={translations[locale].presentation} className="anchor"></div>
            <h2 className="title">
                {translations[locale].presentation}
            </h2>
            <div className={`presentation__text ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                <div className={`presentation__text__image ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    <img className="presentation__text__image__img"
                        src={image}
                        alt="Nicolas est en train de ramener des barres d'haltères à leur place." />
                </div>
                <p className={`${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}
                    dangerouslySetInnerHTML={{ __html: translations[locale].presentation_text }}>
                </p>
            </div>
        </div>
    )
}