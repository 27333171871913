import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { Locale, Window } from '../App';

export default function Contact() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const [fullName, setFullName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [prestations, setPrestations] = useState([]);
    const [situation, setSituation] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const socials = [
        {
            icon: "fa-brands fa-instagram",
            label: "Instagram",
            link: "https://instagram.com/nicofit_marseille?utm_source=qr",
        },
        {
            icon: "fa-brands fa-tiktok",
            label: "Tiktok",
            link: "https://www.tiktok.com/@nicolasfit1?_t=8gOGUsRaOxd&_r=1",
        },
        {
            icon: "fa-brands fa-x-twitter",
            label: "x.com",
            link: "https://x.com/nicofit_",
        },
        {
            icon: "fa-brands fa-facebook-f",
            label: "Facebook",
            link: "https://www.facebook.com/nicoandfit",
        },
    ];

    const contacts = [
        {
            icon: "fa-solid fa-phone",
            label: translations[locale].par_telephone,
            link: "tel:0750522130",
        },
        {
            icon: "fa-solid fa-envelope",
            label: translations[locale].par_mail,
            link: "mailto:nico.coaching.contact@gmail.com",
        },
    ];

    const services = [
        translations[locale].perte_de_poids,
        translations[locale].prise_de_masse,
        translations[locale].endurance,
        translations[locale].remise_en_forme,
        translations[locale].programmation,
        translations[locale].en_presentiel,
        translations[locale].a_distance,
        translations[locale].seance_simple_alt,
        translations[locale].carnet_de_seances,
        translations[locale].abonnement_court,
        translations[locale].abonnement_long,
        translations[locale].programme_personnalise,
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (fullName !== "" && (mail !== "" || phone !== "") && situation !== "") {
            setLoading(true);

            const newMail = {
                id: uuidv4(),
                from: `${fullName} <${(mail != "" ? mail : "client.anonyme@no-reply.com")}>`,
                to: "nico.coaching.contact@gmail.com",
                message: {
                    subject: "Nouveau message sur Nico&Fit",
                    html: "<strong>Moyens de contact renseignés</strong>" + (mail != "" ? ("<br />" + mail) : "") + (phone != "" ? ("<br />" + phone) : "") + "<br /><br /><strong>Prestations sélectionnées</strong><br />" + (prestations.length == 0 ? "Aucune." : prestations.join(", ")) + "<br /><br /><strong>Situation</strong><br />" + situation.replace(/\r?\n/g, "<br />"),
                },
            };

            if (address === "") {
                try {
                    const mailRef = doc(collection(db, "mail"), newMail.id);
                    await setDoc(mailRef, newMail);
                    setValidated(true);
                    setLoading(false);
                } catch (error) {
                    setError(true);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setValidated(true);
            }
        }
    }

    return (
        <div className={`contact anchor_target ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
            <div id={translations[locale].contact} className="anchor"></div>
            <h2 className="title">
                {translations[locale].contact}
            </h2>
            <p className="contact__title">
                {translations[locale].suis_moi}
            </p>
            <div className={`contact__socials ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                {socials.map((social, index) => (
                    <a key={index}
                        href={social.link}
                        className={`contact__socials__item ${social.icon} ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <FontAwesomeIcon icon={social.icon} />
                        {social.label}
                    </a>
                ))}
            </div>
            <div className="contact__contact">
                <div className="contact__form__separator"></div>
                <p className="contact__title">
                    {translations[locale].contacte_moi_directement}
                </p>
                <div className={`contact__socials ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    {contacts.map((contact, index) => (
                        <a key={index}
                            href={contact.link}
                            className={`contact__socials__item contact-${index} ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                            <FontAwesomeIcon icon={contact.icon} />
                            {contact.label}
                        </a>
                    ))}
                </div>
            </div>
            {!validated ?
                <form onSubmit={handleSubmit}
                    className="contact__form">
                    <div className="contact__form__separator"></div>
                    <p className="contact__title">
                        {translations[locale].ou_contacte_moi_par_message}
                    </p>
                    <div className="contact__form__item">
                        <label htmlFor="full-name"
                            className={`contact__form__item__label full_name${submitted && fullName == "" ? " error" : ""}`}>
                            {translations[locale].comment_tu_tappelles}
                        </label>
                        <div className="contact__form__item__container">
                            <input id="full-name"
                                type="text"
                                className={`contact__form__item__container__input${submitted && fullName == "" ? " error" : ""}`}
                                placeholder="Jean Dupont"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)} />
                        </div>
                    </div>
                    <React.Fragment>
                        <div className="contact__form__separator alt"></div>
                        <div className="contact__form__item">
                            <p className="contact__form__item__disclaimer">
                                {translations[locale].renseigne_au_moins}
                            </p>
                            <React.Fragment>
                                <label htmlFor="mail"
                                    className={`contact__form__item__label mail${submitted && (mail == "" && phone == "") ? " error" : ""}`}>
                                    {translations[locale].mail}
                                </label>
                                <div className="contact__form__item__container">
                                    <input id="mail"
                                        type="mail"
                                        className={`contact__form__item__container__input${submitted && (mail == "" && phone == "") ? " error" : ""}`}
                                        placeholder="jean.dupont@example.com"
                                        value={mail}
                                        onChange={(e) => setMail(e.target.value)} />
                                </div>
                            </React.Fragment>
                            <React.Fragment>
                                <label htmlFor="phone"
                                    className={`contact__form__item__label alt phone${submitted && (mail == "" && phone == "") ? " error" : ""}`}>
                                    {translations[locale].telephone}
                                </label>
                                <div className="contact__form__item__container">
                                    <input id="phone"
                                        type="tel"
                                        className={`contact__form__item__container__input${submitted && (mail == "" && phone == "") ? " error" : ""}`}
                                        placeholder="0601020304"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)} />
                                </div>
                            </React.Fragment>
                            <React.Fragment>
                                <label htmlFor="address"
                                    className={`contact__form__item__label alt address${submitted && address == "" ? " error" : ""}`}
                                    hidden={true}>
                                    {translations[locale].adresse_postale}
                                </label>
                                <div className="contact__form__item__container">
                                    <input id="address"
                                        type="text"
                                        className={`contact__form__item__container__input${submitted && address == "" ? " error" : ""}`}
                                        placeholder="123, Rue des Acacias"
                                        value={address}
                                        autoComplete="off"
                                        tabIndex={-1}
                                        onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </React.Fragment>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className="contact__form__separator alt"></div>
                        <div className="contact__form__item">
                            <p className="contact__form__item__disclaimer">
                                {translations[locale].prestations_susceptibles}
                            </p>
                            <div className="contact__form__item__services">
                                {services.map((service, index) => (
                                    <label key={index}
                                        htmlFor={"checkbox-" + service}
                                        className={"contact__form__item__services__item " + service}
                                        onClick={() => {
                                            if (!prestations.includes(service)) {
                                                setPrestations(prevPrestations => [...prevPrestations, service]);
                                            }
                                        }}>
                                        {service}
                                        <input id={"checkbox-" + service}
                                            type="checkbox"
                                            className="contact__form__item__services__item__checkbox" />
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="contact__form__item">
                            <label htmlFor="situation"
                                className={`contact__form__item__label textarea situation${submitted && situation == "" ? " error" : ""}`}>
                                {translations[locale].decris_ta_situation}
                            </label>
                            <div className="contact__form__item__container alt situation">
                                <textarea id="situation"
                                    className={`contact__form__item__container__textarea${submitted && situation == "" ? " error" : ""}`}
                                    placeholder={translations[locale].ecris_ici}
                                    value={situation}
                                    onChange={(e) => setSituation(e.target.value)}>
                                </textarea>
                            </div>
                        </div>
                    </React.Fragment>
                    {submitted && (fullName == "" || (mail == "" && phone == "") || situation == "") &&
                        <p className="contact__form__error">
                            {translations[locale].erreur_formulaire}
                        </p>
                    }
                    {loading ?
                        <button className="contact__form__submit loading"
                            type="button"
                            disabled={true}>
                            {translations[locale].soumettre_ma_demande}
                            <FontAwesomeIcon icon="fa-solid fa-spinner" />
                        </button>
                        : <button className="contact__form__submit"
                            onClick={() => setSubmitted(true)}>
                            {translations[locale].soumettre_ma_demande}
                        </button>
                    }
                </form>
                : <React.Fragment>
                    <div className="contact__form__separator"></div>
                    {validated && !error ?
                        <p className="contact__validated">
                            {translations[locale].formulaire_valide}
                        </p>
                        : <p className="contact__error">
                            {translations[locale].formulaire_invalide}
                        </p>
                    }
                </React.Fragment>
            }
        </div>
    )
}