import { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Window } from '../App';
import { Locale } from '../App';
import image1 from '../assets/hero-1.jpg';
import image2 from '../assets/hero-2.jpg';
import image3 from '../assets/hero-3.jpg';
import image4 from '../assets/hero-4.jpg';

export default function Hero() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const [index, setIndex] = useState(Math.floor(Math.random() * 4));
    const [changed, setChanged] = useState(false);

    const images = [image1, image2, image3, image4];
    const services = [
        {
            id: "service-domicile",
            label: translations[locale].a_domicile,
            icon: "fa-solid fa-house"
        },
        {
            id: "service-distance",
            label: translations[locale].a_distance,
            icon: "fa-solid fa-tv"
        },
        {
            id: "service-exterieur",
            label: translations[locale].en_exterieur,
            icon: "fa-solid fa-tree"
        },
    ];

    useEffect(() => {
        setTimeout(() => {
            setIndex((prevIndex) => prevIndex === images.length - 1 ? 0 : prevIndex + 1);
            setChanged(true);

            setTimeout(() => {
                setChanged(false);
            }, 1000);
        }, 5000);

        return () => { };
    }, [index]);

    return (
        <div className={`hero ${windowSizes.width < breakpoints.tablet ? "sm" : "lg"}`}>
            <div className="hero__cover">
                <img className={`hero__cover__img ${changed ? " changed" : ""}`}
                    src={images[index]}
                    alt="Une coach sportive entraîne un client qui effectue des pompes"
                    loading="lazy" />
            </div>
            <div className={`hero__body ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                <div className={`hero__body__logo ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                    Nico&Fit
                </div>
                <h1 className="hero__body__text">
                    {translations[locale].depasse_tes_limites}<br />
                    {translations[locale].atteins_tes_objectifs}
                </h1>
                <div className={`hero__body__services ${windowSizes.width < breakpoints.tablet ? "sm" : "lg"}`}>
                    {services.map((service, index) => (
                        <div key={index}
                            className={`hero__body__services__item ${windowSizes.width < breakpoints.tablet ? "sm" : "lg"}`}>
                            <div id={service.id}
                                className={`hero__body__services__item__icon ${windowSizes.width < breakpoints.tablet ? "sm" : "lg"}`}>
                                <FontAwesomeIcon icon={service.icon} />
                            </div>
                            <label htmlFor={service.id} className="hero__body__services__item__label">
                                {service.label}
                            </label>
                        </div>
                    ))}
                </div>
                <div className={`hero__body__buttons ${windowSizes.width < breakpoints.tablet ? "sm" : "lg"}`}>
                    <a className="hero__body__buttons__button"
                        href={`#${translations[locale].contact}`}>
                        {translations[locale].bouton_premier_bilan}
                    </a>
                    <a className="hero__body__buttons__button secondary"
                        href={`#${translations[locale].presentation}`}>
                        {translations[locale].bouton_en_savoir}
                    </a>
                </div>
            </div>
        </div>
    )
}