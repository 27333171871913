import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Locale, Window } from '../App';
import logo from '../assets/N&F Logo - White.png';

export default function Footer() {
    const { windowSizes, scrollPosition, breakpoints } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    return (
        <div className={`footer__container ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
            <div className={`footer ${windowSizes.width < breakpoints.tablet ? "sm" : (windowSizes.width >= breakpoints.desktop ? "lg" : "md")}`}>
                <div className="footer__plan">
                    <p className="footer__plan__title">
                        {translations[locale].plan_du_site}
                    </p>
                    <div className="footer__plan__items">
                        {translations[locale].sections.map((section, index) => (
                            <a key={index}
                                href={`#${section === "Présentation" ? "Presentation" : section}`}
                                className="footer__plan__items__item">
                                <FontAwesomeIcon icon="fa-solid fa-link" />
                                <span>{section}</span>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="footer__copyright">
                    <div className="footer__copyright__logo">
                        <img className="footer__copyright__logo__img"
                            src={logo}
                            alt="Nico&Fit Logo" />
                        <p>
                            Nico&Fit
                        </p>
                    </div>
                    © 2023 Nico&Fit. {translations[locale].tous_droits_reserves}.<br />
                    {translations[locale].developpe_par}
                    <a href="https://www.linkedin.com/in/benoit-ricard-dev/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Benoit Ricard
                    </a>.
                </div>
            </div>
        </div>
    )
}